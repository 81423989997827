<!-- <div style="height: 30px; font-size: 13px; text-align: center; color: rgba(134,134,134,0.71)">

</div> -->
<div style="text-align: center; padding: 25px 10px">
    <img height="105px" src="/assets/logo.png" alt="">
</div>
<div class="pin-view">
    <div style="color: rgba(134,134,134,0.71); font-size: 13px; font-weight: bold; text-align: center; padding-bottom: 20px;" *ngIf="isTrial">
        <p style="margin: 0; padding: 3px 0">{{ studyNumber }}</p>
    </div>
    <div class="pin-digits">
        <ion-icon [ngClass]="{ on: input.length >= 1 }" name="{{ input.length >= 1 ? 'radio-button-on' : 'radio-button-off' }}"></ion-icon>
        <ion-icon [ngClass]="{ on: input.length >= 2 }" name="{{ input.length >= 2 ? 'radio-button-on' : 'radio-button-off' }}"></ion-icon>
        <ion-icon [ngClass]="{ on: input.length >= 3 }" name="{{ input.length >= 3 ? 'radio-button-on' : 'radio-button-off' }}"></ion-icon>
        <ion-icon [ngClass]="{ on: input.length >= 4 }" name="{{ input.length >= 4 ? 'radio-button-on' : 'radio-button-off' }}"></ion-icon>
    </div>
    <div style="height: 30px; font-size: 13px; text-align: center; color: rgba(134,134,134,0.71)">
        <p style="margin: 0; padding: 3px 0" *ngIf="lockTimer > 0">Du hast zu häufig eine falsche PIN eingegeben.</p>
        <p style="margin: 0; padding: 3px 0" *ngIf="lockTimer > 0">Eingabe noch <b>{{lockTimer}} Sekunden</b> gesperrt.</p>
        <p style="margin: 0; padding: 3px 0" *ngIf="showPINDescription">Die PIN muss aus 4 Zahlen bestehen</p>
    </div>
    <div class="pin-inputs">
        <div class="buttonHolder">
            <div class="button-box">
                <a href="javascript:void(0)" data-value="1" (click)="enterDigit(1)" class="button"></a>
            </div>
            <div class="button-box">
                <a href="javascript:void(0)" data-value="2" (click)="enterDigit(2)" class="button"></a>
            </div>
            <div class="button-box">
                <a href="javascript:void(0)" data-value="3" (click)="enterDigit(3)" class="button"></a>
            </div>
        </div>
        <div class="buttonHolder">
            <div class="button-box">
                <a href="javascript:void(0)" data-value="4" (click)="enterDigit(4)" class="button"></a>
            </div>
            <div class="button-box">
                <a href="javascript:void(0)" data-value="5" (click)="enterDigit(5)" class="button"></a>
            </div>
            <div class="button-box">
                <a href="javascript:void(0)" data-value="6" (click)="enterDigit(6)" class="button"></a>
            </div>
        </div>
        <div class="buttonHolder">
            <div class="button-box">
                <a href="javascript:void(0)" data-value="7" (click)="enterDigit(7)" class="button"></a>
            </div>
            <div class="button-box">
                <a href="javascript:void(0)" data-value="8" (click)="enterDigit(8)" class="button"></a>
            </div>
            <div class="button-box">
                <a href="javascript:void(0)" data-value="9" (click)="enterDigit(9)" class="button"></a>
            </div>
        </div>
        <div class="buttonHolder">
            <div class="button-box">
                <a href="javascript:void(0)" data-value="&#8856;" (click)="clear()" class="button button-clear"></a>
            </div>
            <div class="button-box">
                <a href="javascript:void(0)" data-value="0" (click)="enterDigit(0)" class="button"></a>
            </div>
            <div class="button-box">
                <a href="javascript:void(0)" data-value="&#9003;" (click)="backspace()" class="button"></a>
            </div>
        </div>
    </div>
</div>
