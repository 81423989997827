import {
    PointerEvent
} from '../../../../../../angular-draggable-droppable/projects/angular-draggable-droppable/src/lib/draggable.directive';
import {interval} from 'rxjs';
import {filter} from 'rxjs/operators';

export class DragAndScroll {
    stepSize: number = 2;
    isDragging: boolean;
    autoScroll: boolean;
    scrollDirection: 'up' | 'down' | 'none' = 'none';
    offset: number;
    currentY: number;

    constructor(private container: HTMLElement) {
        this.autoScroll = false;
        this.offset = 0;
        // console.log('new DragAndScroll', this);
        interval(10).pipe(
            filter(_ => this.autoScroll && this.scrollDirection !== 'none')
        ).subscribe(_ => {
            this.updateState();
            if (this.scrollDirection === 'up')
                this.scrollUp();
            else
                this.scrollDown();
        });
    }

    get edgeTop(): number {
        if (!this.container)
            return 0;
        return this.container.offsetTop;
    }

    get edgeBottom(): number {
        if (!this.container)
            return 0;
        return this.container.clientHeight + this.container.offsetTop;
    }

    get canScrollUp(): boolean {
        return this.container.scrollTop >= 0;
    }

    get canScrollDown(): boolean {
        return (this.container.scrollHeight - this.container.scrollTop) > this.container.clientHeight;
    }

    scrollUp() {
        this.offset -= this.stepSize;
        this.container.scrollBy({ top: -this.stepSize })
    }

    scrollDown() {
        this.offset += this.stepSize;
        this.container.scrollBy({ top: this.stepSize })
    }

    isInsideScrollUpSection(y): boolean {
        const min = this.edgeTop * 0.93;
        const max = this.edgeTop * 1.07;
        return y >= min && y <= max;
    }

    isInsideScrollDownSection(y): boolean {
        const min = this.edgeBottom * 0.93;
        const max = this.edgeBottom * 1.07;
        return y >= min && y <= max;
    }

    resetOffset() {
        this.offset = 0;
    }

    move(event: PointerEvent) {
        // console.log('move', event, this);
        if (!this.isDragging)
            return;

        if (!event)
            return;

        this.currentY = event.clientY;
        this.updateState();
    }

    updateState() {
        if (this.canScrollDown && this.isInsideScrollDownSection(this.currentY)) {
            this.scrollDirection = 'down';
            this.autoScroll = true;
        } else if (this.canScrollUp && this.isInsideScrollUpSection(this.currentY)) {
            this.scrollDirection = 'up';
            this.autoScroll = true;
        } else {
            this.scrollDirection = 'none';
            this.autoScroll = false;
        }
    }

    dragStart() {
        this.offset = 0;
        this.scrollDirection = 'none';
        this.autoScroll = false;
        this.isDragging = true;
    }

    dragEnd() {
        this.autoScroll = false;
        this.isDragging = true;
    }
}
