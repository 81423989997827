/* tslint:disable */

declare var Object: any;
export interface DeviceInterface {
  "deviceId": string;
  "manufacturer"?: string;
  "model"?: string;
  "version"?: string;
  "platform"?: string;
  "name"?: string;
}

export class Device implements DeviceInterface {
    "deviceId": string;
    "manufacturer"?: string;
    "model"?: string;
    "version"?: string;
    "platform"?: string;
    "name"?: string;
  constructor(data?: DeviceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Device`.
   */
  public static getModelName() {
    return "Device";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Device for dynamic purposes.
  **/
  public static factory(data: DeviceInterface): Device{
    return new Device(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Device',
      plural: 'Devices',
      path: 'Devices',
      idName: 'deviceId',
      properties: {
        "deviceId": {
          name: 'deviceId',
          type: 'string'
        },
        "manufacturer": {
          name: 'manufacturer',
          type: 'string'
        },
        "model": {
          name: 'model',
          type: 'string'
        },
        "version": {
          name: 'version',
          type: 'string'
        },
        "platform": {
          name: 'platform',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
