import { RxCollection, RxDatabase, RxReplicationState, addRxPlugin } from 'rxdb';
import { DATABASE_COLLECTIONS, DATABASE_MODEL_SCHEMA, DATABASE_SCHEMA_NAMES } from '@library/core-models/src/schema';
import { DatabaseCollectionProvider, SyncSettings } from './collections/database-collection.provider';
import { Injectable } from '@angular/core';
import AdapterHttp from 'pouchdb-adapter-http';
import AdapterIdb from 'pouchdb-adapter-idb';
import { interval, Observable, Subscription, timer } from 'rxjs';
import PouchDB from 'pouchdb';
import {environment} from '../../../../src/user/environments/environment';
import {AppGlobals} from 'src/user/app/app.global';
import {Device} from '@ionic-native/device/ngx';
import {DeviceService} from 'src/user/app/device.service';
import {Platform} from '@ionic/angular';


PouchDB.plugin(AdapterHttp);
PouchDB.plugin(AdapterIdb);
addRxPlugin(AdapterHttp);
addRxPlugin(AdapterIdb);

@Injectable({ providedIn: 'root' })
export class DatabaseService {
    private db: RxDatabase;
    private syncStarted: boolean;
    private installed: boolean;
    private installing: Promise<void>;
    private syncSettings: SyncSettings;
    private syncStates: RxReplicationState[];
    private syncThread: Subscription;
    private updateSub: Subscription;
    private streamUpdateSub: Subscription;
    private settingsState: RxReplicationState;

    constructor(private collectionProvider: DatabaseCollectionProvider,
                private appGlobals: AppGlobals,
                private device: Device,
                private platform: Platform,
                private deviceService: DeviceService)
    {
        this.syncStates = [];
        this.syncStarted = false;
        this.installed = false;
        this.installing = this.install();
    }

    get settingsStream() {
        return this.settingsState;
    }

    async apiDomain(): Promise<string> {
        await this.databaseAwaiter();
        return this.collectionProvider.getApiDomain();
    }

    async loadSyncSettings() {
        if (environment.disableSync)
            return;

        this.syncSettings = await this.collectionProvider.getSyncSettings();
    }

    async databaseAwaiter(): Promise<DatabaseCollectionProvider> {
        await this.installing;
        await this.install();
        return this.collectionProvider;
    }

    private async install() {
        if (this.installed)
            return;

        await this.platform.ready();
        await this.collectionProvider.connect();

        const names = Object.values(DATABASE_COLLECTIONS);
        for (let name of names) {
            await this.collectionProvider.install(name);
        }

        this.db = await this.collectionProvider.database();
        this.syncStates = [];
        this.installed = true;
    }

    async destroy() {
        await this.db.remove();
    }

    async disconnect() {
        if (environment.disableSync)
            return;

        await this.stopSync();
        await this.collectionProvider.removeSyncSettings()
    }

    async reconnect() {
        if (!localStorage) {
            return false;
        }

        const settings = await this.collectionProvider.getSyncSettings();
        if (!settings) {
            return false;
        }

        // console.log('reconnecting...', settings);
        localStorage.setItem('sync-settings', JSON.stringify(settings));

        await this.disconnect();
        await this.destroy();
        return true;
    }

    async stopSync() {
        if (environment.disableSync)
            return;

        if (!this.syncStarted)
            return;

        if (!this.syncThread)
            return;

        await this.databaseAwaiter();

        this.syncThread.unsubscribe();
        this.syncStarted = false;
    }

    async restartSync() {
        if (environment.disableSync)
            return;

        await this.stopSync();
        await this.startSync();
    }

    async startStreamSync(names: string[], timeout: number, _interval: number = 0) {
        if (environment.disableSync)
            return;

        await this.loadSyncSettings();

        let sync = async () => {
            for (let key of names) {
                let name = DATABASE_MODEL_SCHEMA[key].name;
                let collection: RxCollection = await this.db.collections[name];
                let remoteName = await this.collectionProvider.getRemoteCollection(key);
                if (!remoteName)
                    continue;

                let remote = new PouchDB(remoteName);
                await PouchDB.replicate(<any>collection.pouch, remote, { timeout: 15000 });
                await PouchDB.replicate(remote, <any>collection.pouch, { timeout: 15000 });
            }
        };

        if (_interval !== 0) {
            // interval() is called after the time interval has passed
            // with this call the app is going to sync every time it starts and every 120 seconds after that
            await sync();
            this.syncThread = interval((_interval + 5) * 1000).subscribe(sync);
        } else {
            await sync();
        }
    }

    async startLiveDBReplication(names: string[] = Object.values(DATABASE_COLLECTIONS)) {
        if (environment.disableSync) {
            return;
        }

        await this.loadSyncSettings();

        for (let key of names) {
            let name = DATABASE_MODEL_SCHEMA[key].name;
            let collection: RxCollection = await this.db.collections[name];
            let remoteName = await this.collectionProvider.getRemoteCollection(key);
            if (!remoteName) {
                continue;
            }

            let remote = new PouchDB(remoteName);

            if (name === 'surveys') {
                await this.startBidirectionalSync(collection, remote);
                continue;
            } else if (name === 'settings') {
                let state = await this.startBidirectionalSync(collection, remote);
                if(!state || !state.docs$){
                    continue;
                }

                this.settingsState = state;
                state.docs$.subscribe(async (doc) => {
                    await this.deviceService.complete();
                    const deviceId = this.deviceService.deviceId;
                    if (doc.value == deviceId) {
                        let settingsCollection = this.collectionProvider.use(DATABASE_COLLECTIONS.SettingsCollection);
                        await settingsCollection.atomicUpsert({ key: 'delete-device', value: 'null' });
                        let delayer = await timer(3000).toPromise();
                        await this.disconnect();
                        await this.destroy().then(() => location.reload());
                    }
                });
                continue;
            }
            // this type of replication is way faster than a live replication
            await PouchDB.sync(<any>collection.pouch, remote).on('complete', async () => {
                // After the sync (we already have the data we needed) we start the live replication that omitts the new values to CouchDB
                // we can use the replicationState to subscribe for different events like alive$ or error$
                let replicationState: RxReplicationState = await <any>collection.sync({
                    remote: remote,
                    waitForLeadership: true,
                    direction: {
                        pull: false,
                        push: true
                    },
                    options: {
                        live: true,
                        retry: true
                    }
                });
            }).on('error', (err) => {
                // console.log('replication error', err);
            });
        }
    }

    async startBidirectionalSync(collection: RxCollection, remote: PouchDB.Database): Promise<RxReplicationState> {
        let state: RxReplicationState = null;
        await PouchDB.sync(<any>collection.pouch, remote).on('complete', async () => {
            state = await <any>collection.sync({
                remote: remote,
                waitForLeadership: true,
                direction: {
                    pull: true,
                    push: true,
                },
                options: {
                    live: true,
                    retry: true
                }
            });
        }).on('error', (err) => {
            // console.log("collection replication error", err, collection);
        });
        return state;
    }

    startSync(useStream: boolean = true) {
        if (environment.disableSync)
            return;

        if (this.syncStarted)
            return;

        this.syncStarted = true;

        if (useStream) {
            this.startStreamSync([
                DATABASE_COLLECTIONS.TimeframeCollection,
                DATABASE_COLLECTIONS.EventCollection,
                DATABASE_COLLECTIONS.LifeSectionCollection
            ], 25, 30);

            this.startStreamSync(
                Object.values(DATABASE_COLLECTIONS), 110, 120);

            if (this.updateSub)
                return;

            this.updateSub = this.db.$.subscribe(async x => {
                if (!x.documentData)
                    return;

                if (x.isLocal)
                    return;

                if (!x.collectionName)
                    return;

                let collection: RxCollection = this.db.collections[x.collectionName];
                if (!collection)
                    return;

                let remoteName = await this.collectionProvider.getRemoteCollection(DATABASE_SCHEMA_NAMES[x.collectionName]);
                if (!remoteName)
                    return;

                let remote = new PouchDB(remoteName);
                remote.setMaxListeners(80);
                (<any>collection.pouch).setMaxListeners(80);
                await PouchDB.replicate(<any>collection.pouch, remote, { timeout: 15000 });
                await PouchDB.replicate(remote, <any>collection.pouch, { timeout: 15000 });
            });
        } else {
            if (this.syncThread)
                this.syncThread.unsubscribe();

            this.syncThread = interval(30000).subscribe(async () => {
                await this.syncNow();
            });
        }
    }

    async syncNow(names: string[] = Object.values(DATABASE_COLLECTIONS)) {
        if (environment.disableSync)
            return;

        await this.loadSyncSettings();
        await this.pullData(names);
        await this.pushData(names);
    }

    async pullData(names: string[] = Object.values(DATABASE_COLLECTIONS)) {
        if (environment.disableSync)
            return;

        await this.loadSyncSettings();
        for (let key of names) {
            let name = DATABASE_MODEL_SCHEMA[key].name;
            let collection: RxCollection = this.db.collections[name];
            let remote = await this.collectionProvider.getRemoteCollection(key);
            if (!remote)
                continue;

            // console.log('replicating...')
            collection.sync({
                remote: remote,
                direction: {
                    pull: true,
                    push: false
                },
                options: {
                    retry: true,
                    live: false
                }
            });
        }
    }

    async pushData(names: string[] = Object.values(DATABASE_COLLECTIONS)) {
        if (environment.disableSync)
            return;

        await this.loadSyncSettings();
        for (let key of names) {
            let name = DATABASE_MODEL_SCHEMA[key].name;
            let collection: RxCollection = await this.db.collections[name];
            let remote = await this.collectionProvider.getRemoteCollection(key);
            if (!remote)
                continue;

            await PouchDB.replicate(collection.pouch['name'], remote);
        }
    }
}
