import {Injectable, InjectionToken, Injector, NgModuleRef} from '@angular/core';
import Jobs from './jobs';
import {AppSettings} from '@plugin/settings';
import {Job} from './job';
import * as Sentry from '@sentry/capacitor';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class JobService {
    constructor(private moduleRef: NgModuleRef<any>, private settings: AppSettings) { }

    async runAllPending() {
        await this.settings.awaiter();
        const jobs = this.settings.get('scheduled-jobs', []);

        if (environment.enableSentryMonitoring) {
            Sentry.addBreadcrumb({
                message: 'pending scheduled-jobs',
                data: jobs
            })
        }

        const pendingJobs = [];

        for (let name of jobs) {
            if (!Jobs[name]) {
                // console.warn(`scheduled job "${name}" is not registered!`);
                pendingJobs.push(name);
                continue;
            }

            const job = this.moduleRef.injector.get<Job>(Jobs[name]);
            // console.log(`running scheduled job "${name}"`)
            if (environment.enableSentryMonitoring) {
                Sentry.captureEvent({
                    message: `running scheduled job "${name}"`
                })
            }

            if (!await job.run()) {
                if (environment.enableSentryMonitoring) {
                    Sentry.captureEvent({
                        message: `scheduled job failed "${name}"`
                    })
                }
                pendingJobs.push(name);
            }
        }

        this.settings.set('scheduled-jobs', pendingJobs);
    }
}
