import {Injectable} from '@angular/core';
import {DatabaseCollectionProvider} from '@core/database';
import {DayStats} from '../models/day-stats';
import {
    LifeSectionModel,
    TimeframeEvaluationModel,
    TimeframeModel,
    DayStatsModel,
    EventTemplateModel
} from '@core/models';
import {Moment} from 'moment';
import * as moment from 'moment';
import {RxCollection} from 'rxdb';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import {DatabaseService} from '@core/database';
import {EventTemplateService} from '@library/plugin-event-templates/src/lib/services/event-template.service';
import {weekday} from '@core/shared';
import {WeekTemplateService} from '@library/plugin-event-templates/src/lib/services/week-template.service';

@Injectable({
    providedIn: 'root'
})
export class DayStatisticService {
    private dayStatsCollection: RxCollection<DayStatsModel>;
    private timeframeCollection: RxCollection<TimeframeModel>;
    private lifeSectionCollection: RxCollection<LifeSectionModel>;
    private timeframeEvaluationCollection: RxCollection<TimeframeEvaluationModel>;

    constructor(collectionProvider: DatabaseCollectionProvider, databaseService: DatabaseService, private templateService: EventTemplateService, private weekTemplateService: WeekTemplateService) {
        databaseService.databaseAwaiter().then(() => {
            this.timeframeCollection = collectionProvider.use<TimeframeModel>(DATABASE_COLLECTIONS.TimeframeCollection);
            this.lifeSectionCollection = collectionProvider.use<LifeSectionModel>(DATABASE_COLLECTIONS.LifeSectionCollection);
            this.dayStatsCollection = collectionProvider.use<DayStatsModel>(DATABASE_COLLECTIONS.DayStatsCollection);
            this.timeframeEvaluationCollection = collectionProvider.use<TimeframeEvaluationModel>(DATABASE_COLLECTIONS.TimeframeEvaluationCollection);
        })
    }

    async exists(id: string): Promise<boolean> {
        let item = await this.dayStatsCollection.findOne(id).exec();
        return !!item;
    }

    async create(date: Moment, templateGroup?: number): Promise<DayStats> {
        const id = date.startOf('day').unix().toString();
        let item = await this.dayStatsCollection.findOne(id).exec();
        if (item) {
            throw new Error('item already exists');
        }

        const weekTemplateGroup = this.weekTemplateService.getActive();

        await this.dayStatsCollection.newDocument({
            id: date.startOf('day').unix().toString(),
            lifeSectionStats: {},
            timeframeEvaluations: [],
            averageConflictRating: null,
            averageResolutionRating: null,
            averageWellbeing: null,
            canceledCount: 0,
            doneCount: 0,
            pendingCount: 0,
            templateGroupIndex: templateGroup === undefined ? weekTemplateGroup.id : templateGroup
        }).save();

        return await this.updateDayStatistic(date, true);
    }

    async getLastDays(start: Moment, end: Moment): Promise<DayStats[]> {
        let result =
            await this.dayStatsCollection
                .find()
                .where('id')
                .gte(start.unix().toString())
                .lte(end.unix().toString())
                .exec();

        return result.map(x => new DayStats(x.toJSON()).writeStats());
    }

    async setTemplateGroupIndex(date: Moment, groupIndex: number) {
        const id = date.startOf('day').unix().toString();
        let item = await this.dayStatsCollection.findOne(id).exec();
        if (item) {
            return await item.atomicUpdate(doc => {
                doc.templateGroupIndex = groupIndex;
                return doc;
            })
        }
        return await this.create(date, groupIndex);
    }

    async getTemplateGroupIndex(date: Moment): Promise<number> {
        const id = date.startOf('day').unix().toString();
        let item = await this.dayStatsCollection.findOne(id).exec();
        if (!item) {
            return this.weekTemplateService.getActive().id
        }

        const active = this.weekTemplateService.getOne(item.toJSON().templateGroupIndex);
        if (!active || active['__deleted']) {
            return this.weekTemplateService.getActive().id
        }

        return active.id;
    }

    async get(date: Moment, canCreate: boolean = false): Promise<DayStats> {
        const id = date.startOf('day').unix().toString();
        if (canCreate && !(await this.exists(id)))
            return await this.create(date);
        return new DayStats((await this.dayStatsCollection.findOne(id).exec()).toJSON());
    }

    async save(item: DayStats): Promise<DayStats> {
        item.writeStats();
        let data = item.toData();
        return new DayStats(
            (await this.dayStatsCollection.atomicUpsert(data)).toJSON()
        );
    }

    async updateDayStatistic(date: Moment, forceUpdate: boolean = false): Promise<DayStats> {
        let stats = await this.get(moment(date), true);
        if (forceUpdate || date.isSameOrAfter(moment().add(12, 'hours'))) {
            stats.timeframes = (
                await this.timeframeCollection
                    .find()
                    .where('start')
                    .gte(date.startOf('day').toISOString())
                    .lte(date.endOf('day').toISOString())
                    .exec()
            ).map(x => x.toJSON());
        }

        stats.timeframeEvaluations = (
            await this.timeframeEvaluationCollection
                .find()
                .where('eventDate')
                .gte(date.startOf('day').toISOString())
                .lte(date.endOf('day').toISOString())
                .exec()
        ).map(x => x.toJSON());

        if (forceUpdate || moment().isBefore(date, 'day')) {
            // console.log('loading event templates');
            stats.eventTemplates = await new Promise<EventTemplateModel[]>(
                resolve => this.templateService.get(stats.templateGroupIndex, weekday(date)).subscribe(x => resolve(x))
            )
        }

        // console.log('stats saved', stats)
        return await this.save(stats);
    }
}
