import {FeatureHandler, MetaFeature} from 'calendar/feature-events/meta-feature';
import {Injectable} from '@angular/core';
import {ActionSheetController, NavController} from '@ionic/angular';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

export interface InternalRouteFeatureOptions {
    route: string;
    params?: string[];
    useAngularRouting?: boolean;
    dialog?: {
        label: string;
        icon: string;
        showCancel: boolean;
        additionalActions?: {
            label: string;
            icon: string;
            forceReload?: boolean;
            handler: () => void | Promise<void>;
        }[];
    }
}

@Injectable({
    providedIn: 'root'
})
export class InternalRouteFeature implements FeatureHandler<InternalRouteFeatureOptions> {
    forceReload$ = new Subject();
    name: string = 'internal-route';

    constructor(private navController: NavController, private router: Router, private actionSheetController: ActionSheetController) { }

    private navigate(route: string, params: string[]) {
        this.navController.navigateForward([route, ...params]);
    }

    private async showActionSheet(options: InternalRouteFeatureOptions) {
        const buttons = [];
        buttons.push({
            text: options.dialog.label,
            icon: options.dialog.icon,
            handler: () => {
                // console.log('navigate to', options.route);
                if (options.useAngularRouting) {
                    this.router.navigate([options.route])
                } else {
                    this.navigate(options.route, options.params || [])
                }
            }
        });

        if (options.dialog.showCancel) {
            buttons.push({
                text: 'Abbrechen',
                role: 'cancel',
                icon: 'close'
            });
        }

        if (options.dialog.additionalActions) {
            options.dialog.additionalActions.forEach(action => {
                buttons.push({
                    text: action.label,
                    icon: action.icon,
                    handler: async () => {
                        await action.handler();
                        if (action.forceReload) {
                            this.forceReload$.next();
                        }
                    }
                });
            });
        }

        let sheet = await this.actionSheetController.create({
            buttons: buttons,
            animated: true
        });
        sheet.present();
    }

    handle(meta: MetaFeature<InternalRouteFeatureOptions>): boolean {
        if (!meta.options.route)
            return true;

        const withDialog = !!meta.options.dialog;
        if (withDialog) {
            this.showActionSheet(meta.options);
        } else {
            this.navigate(meta.options.route, meta.options.params || []);
        }

        return true;
    }
}
