import {ErrorHandler, Injectable} from '@angular/core';
import {NavController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppSettings} from '@plugin/settings';
import * as Sentry from '@sentry/capacitor';
import {environment} from '../environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private notifyErrors: boolean;
    private nonCriticalErrorMessages = [
        'Uncaught (in promise): Error: requested model does not exist',
        'Uncaught (in promise): Error: no database connected',
        'Uncaught (in promise): Error: requested collection was not installed yet!'
    ];

    constructor(private navController: NavController, private snackBar: MatSnackBar, private settings: AppSettings) {
        settings.changes('show-exceptions').subscribe(x => this.notifyErrors = x.value);
        settings.awaiter().then(() => {
            this.notifyErrors = settings.get('show-exceptions', false);
        });
    }

    updateScope() {
        if (!this.settings.ready)
            return;

        try {
            Sentry.withScope(async scope => {
                await this.settings.awaiter();
                const iid = this.settings.get('internalId')

                const studyNumber = this.settings.get('study-number');
                const updateChannel = this.settings.get('update-channel') || 'master';

                if (iid && studyNumber) {
                    scope.setUser({
                        id: iid,
                        username: this.settings.get('study-number')
                    })
                }

                scope.setExtra('update-channel', updateChannel);
                !!studyNumber ? scope.setExtra('user-ref', studyNumber) : null;
                !!iid ? scope.setExtra('user-id', iid) : null;
                !!this.settings.endpointSettings.name ? scope.setExtra('endpoint', this.settings.endpointSettings.name) : null;
            })
        } catch (e) {
            // console.log('sentry error configuring scope >', e);
        }
    }

    handleError(error: any): void {
        if (environment.enableSentryMonitoring) {
            Sentry.captureException(error.originalError || error);
        }

        const message = error.message ? error.message : error.toString();
        if (message) {
            if (this.notifyErrors)
                this.snackBar.open(message, null, { duration: 10000 });

            for (let nonCriticalMessage of this.nonCriticalErrorMessages) {
                if (message.startsWith(nonCriticalMessage)) {
                    // console.warn('Non-critical error occurred:', `"${nonCriticalMessage}"`, 'redirecting to root...');
                    this.navController.navigateRoot('/');
                    return;
                }
            }
        }

        throw error;
    }
}
