import {Job} from '../job';
import {DatabaseCollectionProvider} from '@core/database';
import {RxCollection} from 'rxdb';
import {DayStatsModel} from '@core/models';
import {AppSettings} from '@plugin/settings';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import {WeekTemplateSwitchService} from '@library/plugin-event-templates/src/lib/services/week-template-switch.service';
import {WeekTemplateService} from '@library/plugin-event-templates/src/lib/services/week-template.service';
import * as moment from 'moment';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as Sentry from '@sentry/capacitor';

@Injectable({
    providedIn: 'root'
})
export class J20200710_WeekTemplate implements Job {
    private dayStatsCollection: RxCollection<DayStatsModel>;

    constructor(private db: DatabaseCollectionProvider,
                private settings: AppSettings,
                private weekTemplateService: WeekTemplateService) { }

    async run(): Promise<boolean> {
        await this.prepare();

        if (!this.isRunRequired()) {
            if (environment.enableSentryMonitoring) {
                Sentry.captureEvent({
                    message: `[J20200710_WeekTemplate]: run not mandatory`,
                    extra: {
                        count: this.settings.count(x => x.key.startsWith('week-template-'))
                    }
                })
            }
            return true;
        }

        const items = await this.dayStatsCollection.find().exec();
        if (environment.enableSentryMonitoring) {
            Sentry.captureEvent({
                message: `[J20200710_WeekTemplate]: found ${items.length} day-stats`,
                extra: {
                    day_stats: Array.isArray(items) ? items.map(x => x.toJSON()) : []
                }
            })
        }

        for (let item of items) {
            const doc = item.toJSON();
            if (environment.enableSentryMonitoring) {
                Sentry.captureEvent({
                    message: `[J20200710_WeekTemplate]: adding switch instruction (groupIndex: ${doc.templateGroupIndex}, time: ${(doc.id || doc._id)}}`,
                    extra: {
                        day_stats: doc
                    }
                })
            }
            // console.log(`[J20200710_WeekTemplate]: adding switch instruction (groupIndex: ${doc.templateGroupIndex}, time: ${(doc.id || doc._id)}}`)
            try {
                await this.weekTemplateService.setActive({id: doc.templateGroupIndex, name: ''}, moment.unix(Number.parseInt((doc.id || doc._id))));
            } catch (e) {
                // console.log('[J20200710_WeekTemplate]: exception')
                if (environment.enableSentryMonitoring) {
                    Sentry.addBreadcrumb({
                        message: 'this.weekTemplateService.setActive(...)',
                        data: {
                            weekTemplate: {id: doc.templateGroupIndex, name: 'UNSET'},
                            timestamp: Number.parseInt((doc.id || doc._id)),
                            isoTime: moment.unix(Number.parseInt((doc.id || doc._id))).toISOString(),
                            doc: doc
                        }
                    })
                    Sentry.captureException(e)
                }
            }
        }

        return true;
    }

    private async prepare() {
        if (environment.enableSentryMonitoring) {
            Sentry.captureEvent({
                message: `[J20200710_WeekTemplate]: preparing`
            })
        }
        await this.settings.awaiter();
        this.dayStatsCollection = this.db.use(DATABASE_COLLECTIONS.DayStatsCollection);
    }

    private isRunRequired(): boolean {
        return this.settings.count(x => x.key.startsWith('week-template-')) > 1
    }
}
