import { Injectable } from '@angular/core';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AppSettings } from '@plugin/settings';
import { AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LiveUpdateService {
    constructor(private http: HttpClient, private settings: AppSettings, private alertController: AlertController) {}

    isNewer(version1: string, version2: string): boolean {
        const parseVersion = (version: string): number[] => {
            return version.split('.').map(Number);
        };

        const v1Parts = parseVersion(version1);
        const v2Parts = parseVersion(version2);

        for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
            const v1Part = v1Parts[i] || 0;
            const v2Part = v2Parts[i] || 0;

            if (v2Part > v1Part) {
                return true;
            }
            if (v1Part > v2Part) {
                return false;
            }
        }

        return false;
    }

    async checkForUpdates() {
        const { version } = environment;
        const updateChannel = this.settings.get('update-channel', 'master');
        const manifest = await this.http.get<Manifest>(`https://static.de-rena.de/apps/derena/manifest.json?t=${Date.now()}`).toPromise().catch(console.error);
        // console.log('get manifest', JSON.stringify(manifest));

        if (!manifest) {
            // console.error('Manifest not found');
            return;
        }

        const { bundle, channel } = manifest.channels.find(x => x.channel === updateChannel);
        if (!channel) {
            // console.error(`Channel ${updateChannel} not found in manifest`);
            return;
        }

        if (this.isNewer(version, bundle.version)) {
            // console.log(`New version available: ${bundle.version}`);
            await LiveUpdate.downloadBundle({
                url: `https://static.de-rena.de/apps/derena/channels/${channel}/${bundle.filename}`,
                bundleId: bundle.version,
            }).catch(console.error);

            await this.applyUpdateDialog(bundle.version);
        }
    }

    async applyUpdateDialog(version: string) {
        const alert = await this.alertController.create({
            id: 'update-available',
            header: 'Neues Update verfügbar',
            message: `Es ist ein neues Update verfügbar. Möchten Sie es jetzt installieren?`,
            buttons: [
                {
                    text: 'Nein',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: 'Ja',
                    handler: async () => {
                        await LiveUpdate.setBundle({ bundleId: version });
                        await LiveUpdate.reload();
                    }
                }
            ]
        });

        await alert.present();
    }

    async disableRollback() {
        await LiveUpdate.ready();
    }
}

export type Manifest = {
    channels: {
        channel: string;
        bundle: {
            version: string;
            filename: string;
        }
    }[];
}
