import {EventEmitter, Injectable} from '@angular/core';
import {DatabaseCollectionProvider, DatabaseRepository, DatabaseService, DbContext} from '@core/database';
import {LifeSectionModel, TimeframeModel} from '@core/models';
import {Observable} from 'rxjs';
import {RxCollection} from 'rxdb';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import {PersistentModel} from '@library/core-database/src/lib/definitions';
import { v4 as uuid } from 'uuid';
import {EventDefinition} from 'calendar/models/event-definition.model';

@Injectable({
    providedIn: 'root'
})
export class LifeSectionService {
    private updater: EventEmitter<LifeSectionModel[]> = new EventEmitter();
    private collection: RxCollection<LifeSectionModel>;
    private timeframeCollection: RxCollection<TimeframeModel>;
    private eventCollection: RxCollection<EventDefinition>;

    constructor(database: DatabaseCollectionProvider, databaseService: DatabaseService) {
        databaseService.databaseAwaiter().then(x => {
            this.collection = database.use<LifeSectionModel>(DATABASE_COLLECTIONS.LifeSectionCollection);
            this.timeframeCollection = database.use<TimeframeModel>(DATABASE_COLLECTIONS.TimeframeCollection);
            this.eventCollection = database.use<EventDefinition>(DATABASE_COLLECTIONS.EventCollection);

            // Todo: make this better
            this.collection.insert$.subscribe(async () => {
                this.emitItems(await this.collection.find().exec())
            });
            this.collection.update$.subscribe(async () => {
                this.emitItems(await this.collection.find().exec())
            });
            this.collection.remove$.subscribe(async (ls) => {
                const id = ls.documentId;
                await this.timeframeCollection.find().where('lifeSectionId').equals(id).remove();
                const events = await this.eventCollection.find().where('meta.options.lifeSectionId').equals(id).exec();
                for (let event of events) {
                    if (event.meta.options.lifeSectionId && event.meta.options.lifeSectionId === id) {
                        event.atomicUpdate(doc => {
                            doc.meta.feature = 'default';
                            doc.meta.options = { };
                            return doc;
                        })
                    }
                }
                this.emitItems(await this.collection.find().exec())
            });
        })

    }

    observeChanges(): Observable<LifeSectionModel[]> {
        return this.updater;
    }

    emitItems(items) {
        this.updater.emit(items
            .map(
                x => x.toJSON()
            )
            .sort(
                (a, b) => a.name.localeCompare(b.name)
            ))
    }

    getAll(): Observable<LifeSectionModel[]> {
        this.collection.find().exec().then(async items => this.emitItems(items));
        return this.updater;
    }

    async getAllOnce(): Promise<LifeSectionModel[]> {
        return (await this.collection.find().exec()).map(x => x.toJSON());
    }

    async get(id: any): Promise<LifeSectionModel> {
        return (await this.collection.findOne(id).exec()).toJSON();
    }

    async save(item: LifeSectionModel): Promise<void> {
        let entry: PersistentModel<LifeSectionModel> = null;

        if (!item.id) {
            entry = this.collection.newDocument(item);
            entry.id = uuid();
            if (item.shortName) {
                entry.shortName = item.shortName.toUpperCase();
            } else {
                entry.shortName = (item.name.length > 3 ? item.name.substr(0, 3) : item.name).toUpperCase();
            }
            entry.conflicts = entry.conflicts || [];
            entry.resolutions = entry.resolutions || [];
            entry.tasks = entry.tasks || [];
            await entry.save();
        } else {
            entry = await this.collection.findOne(item.id).exec();
            await entry.atomicUpdate(doc => {
                doc.name = item.name;
                doc.shortName = item.shortName.toUpperCase();
                doc.color = item.color;
                doc.conflicts = item.conflicts;
                doc.resolutions = item.resolutions;
                doc.enableNotifications = item.enableNotifications;
                doc.enableEvaluation = item.enableEvaluation;
                doc.enableSync = item.enableSync;
                doc.tasks = item.tasks;
                return doc;
            });
        }
    }

    async delete(item: LifeSectionModel): Promise<void> {
        let entry = await this.collection.findOne(item.id).exec();
        await entry.remove();
    }
}
