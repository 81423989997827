import {Injectable} from '@angular/core';
import {AppSettings} from '@plugin/settings';
import {DatabaseCollectionProvider} from '@core/database';
import {RxCollection, RxDocumentTypeWithRev} from 'rxdb';
import {SurveyModel, SurveyResultModel} from '@core/models';
import {Moment} from 'moment';
import * as moment from 'moment';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {FeaturedEventProvider} from 'calendar/feature-events/meta-feature';
import {InternalRouteFeature} from 'calendar/feature-events/internal-route.feature';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import {CalendarEvent} from 'calendar-utils';
import {SurveyEventProvider} from 'survey/services/survey-event.provider';
import {StaticSurveyEventProvider} from 'survey/services/providers/static-survey-event.provider';
import {DynamicSurveyEventProvider} from 'survey/services/providers/dynamic-survey-event.provider';

@Injectable({
    providedIn: 'root'
})
export class SurveyEventService implements FeaturedEventProvider<InternalRouteFeature> {
    private settings: AppSettings;
    private surveyCollection: RxCollection<SurveyModel>;
    private surveyResultCollection: RxCollection<SurveyResultModel>;
    private active: boolean;

    private providers: {[key: string]: SurveyEventProvider};

    activate(settings: AppSettings, collectionProvider: DatabaseCollectionProvider) {
        this.active = true;
        this.settings = settings;
        this.surveyCollection = collectionProvider.use<SurveyModel>(DATABASE_COLLECTIONS.SurveyCollection);
        this.surveyResultCollection = collectionProvider.use<SurveyResultModel>(DATABASE_COLLECTIONS.SurveyResultCollection);
        this.providers = {
            'static': new StaticSurveyEventProvider(),
            'dynamic': new DynamicSurveyEventProvider(collectionProvider)
        };
    }

    async getEvents(start: Moment, end: Moment): Promise<EventDefinition[]> {
        if (!this.active)
            return [];

        const surveyDocuments = await this.surveyCollection.find().exec();
        const surveys = surveyDocuments.map(x => x.toJSON());

        const result: CalendarEvent[] = [];
        for (let survey of surveys) {
            if (!survey.type) survey.type = 'dynamic';
            result.push(...await this.providers[survey.type].getEvents(survey))
        }

        // console.log('survey events', result, surveys);

        return result;
    }
}
