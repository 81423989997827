import { Injectable } from '@angular/core';
import { DatabaseRepository, DbContext } from '@core/database';
import { SurveyModel } from '@library/core-models/src/survey.model';
import { SurveyResultModel } from '@library/core-models/src/survey-result.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  surveyDb: DbContext<SurveyModel>;
  surveyResultDb: DbContext<SurveyResultModel>;

  constructor(private db: DatabaseRepository) {
    this.init();
  }

  init() {
    this.surveyDb = this.db.use<SurveyModel>('Survey');
    this.surveyResultDb = this.db.use<SurveyResultModel>('SurveyResult');
  }

  async getQuestions(): Promise<any[]>{
    return (await this.surveyDb.find())[0].questions;
  }

  // submits the result of the survey and returns true if the operation was successful
  async submitSurvey(survey: SurveyResultModel): Promise<boolean> {
    // console.log("submitSurvey() is not implemented yet, returns always false");
    // this.surveyResultDb.save(survey);
    return false;
  };
}
