import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Moment} from 'moment';
import {AppSettings} from '@plugin/settings';
import { Router } from '@angular/router';
import { LocalNotifications, PendingLocalNotificationSchema } from '@capacitor/local-notifications';
import { LoggingService } from '../../../../src/user/app/logging.service';
import { LocalNotificationSchema } from '@capacitor/local-notifications/dist/esm/definitions';

export const DEFAULT_NOTIFICATION_ID = 1000;
export const DEBUG = false;

@Injectable({
    providedIn: 'root'
})
export class DeviceNotificationService {
    initialized: boolean;
    permissionGranted: boolean;
    supported: boolean;
    nextId: number;

    constructor(private platform: Platform, private settings: AppSettings, private router: Router, private logging: LoggingService) {
        this.initialized = false;
        this.permissionGranted = false;
        this.supported = false;
    }

    /**
     * | Platform Name   | Description                        |
     * |-----------------|------------------------------------|
     * | android         | on a device running Android.       |
     * | cordova         | on a device running Cordova.       |
     * | ios             | on a device running iOS.           |
     * | ipad            | on an iPad device.                 |
     * | iphone          | on an iPhone device.               |
     * | phablet         | on a phablet device.               |
     * | tablet          | on a tablet device.                |
     * | electron        | in Electron on a desktop device.   |
     * | pwa             | as a PWA app.   |
     * | mobile          | on a mobile device.                |
     * | desktop         | on a desktop device.               |
     * | hybrid          | is a cordova or capacitor app.     | */
    isSupported(): boolean {
        return (this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova');
    }

    async setup() {
        try {
            await this.platform.ready();
            this.supported = this.isSupported();
            if (!this.supported) {
                return false;
            }

            this.permissionGranted = await LocalNotifications.checkPermissions().then(x => x.display === 'granted');
            if (!this.permissionGranted) {
                this.permissionGranted = await LocalNotifications.requestPermissions().then(x => x.display === 'granted');
                if (!this.permissionGranted) {
                    return false;
                }
            }

            this.nextId = this.settings.get('next-notification-id', DEFAULT_NOTIFICATION_ID);
            this.initialized = true;

            this.__debug('setup');

            return true;
        } catch (e) {
            // console.error('DeviceNotificationService.setup() failed', e);
            return false;
        }
    }

    async reset() {
        try {
            if (!this.initialized)
                return;

            // console.error('DeviceNotificationService.reset() is not implemented yet');
            this.settings.set('next-notification-id', DEFAULT_NOTIFICATION_ID);
        } catch (e) {
            // console.error('DeviceNotificationService.reset() failed', e);
        }
    }

    async remove(id: number) {
        try {
            if (!this.initialized)
                return;

            // console.log('remove notification', id);
            await LocalNotifications.cancel({notifications: [{id: id}]});
        } catch (e) {
            // console.error('DeviceNotificationService.remove() failed', e);
        }
    }

    async update(id: number, options: Omit<LocalNotificationSchema, 'id'>) {
        try {
            if (!this.initialized)
                return;

            const notification = {
                ...options,
                id: id,
            };

            await this.remove(id);
            setTimeout(() => {this.send(notification, id)}, 1000);
        } catch (e) {
            // console.error('DeviceNotificationService.update() failed', e);
        }
    }

    send(options: Omit<LocalNotificationSchema, 'id'>, notificationId?: number): number {
        const notification: LocalNotificationSchema = {
            ...options,
            id: 0,
        };

        try {
            if (!this.initialized)
                return null;
            let id = null;
            if(!!notificationId && notificationId !== 0){
                id = notificationId;
            } else {
                id = this.getNextId();
                this.settings.set('next-notification-id', id + 1);
            }
            notification.id = id;
            LocalNotifications.schedule({
                notifications: [notification]
            });
            return id;
        } catch (e) {
            // console.error('DeviceNotificationService.send() failed', e);
            return null;
        }
    }

    sendDirect(title: string, text: string = '', vibrate: boolean = true): number {
        try {
            if (!this.initialized)
                return null;

            const id = this.getNextId();
            LocalNotifications.schedule({
                notifications: [{
                    id: id,
                    title: title,
                    body: text,
                }]
            });

            this.settings.set('next-notification-id', id + 1);
            this.__debug('sendDirect');

            return id;
        } catch (e) {
            // console.error('DeviceNotificationService.sendDirect() failed', e);
            return null;
        }
    }

    schedule(title: string, text: string = '', vibrate: boolean = true, date: Moment): number {
        try {
            if (!this.initialized)
                return null;

            const id = this.getNextId();
            LocalNotifications.schedule({
                notifications: [
                    {
                        id,
                        title,
                        body: text,
                        schedule: {
                            at: date.toDate(),
                        },
                    }
                ]
            });

            this.settings.set('next-notification-id', id + 1);
            this.__debug('schedule');

            return id;
        } catch (e) {
            // console.error('DeviceNotificationService.schedule() failed', e);
            return null;
        }
    }

    getNextId() {
        return this.nextId++;
    }

    private async __debug(message: string) {
        if (!DEBUG) {
            return;
        }

        const { notifications } = await LocalNotifications.getPending();
        // console.log('---- DeviceNotificationService ----');
        // console.log('Message:', message);
        // console.log('Notifications:');
        for (const notification of notifications) {
            // console.log('\t', JSON.stringify(notification));
        }
        // console.log('------------------------------------');
    }

    subscribeOnClick() {
        if (!this.supported)
            return;

        // Todo: Notification source can also be a day evaluation or survey
        LocalNotifications.addListener('localNotificationActionPerformed', () => {
            this.router.navigate(['todo']);
        });
    }

    async getPending(): Promise<PendingLocalNotificationSchema[]> {
        if (!this.supported)
            return [];

        return (await LocalNotifications.getPending()).notifications;
    }
}
