import {Injectable} from '@angular/core';
import * as Sentry from '@sentry/capacitor';
import { AppSettings } from '@plugin/settings';
import { SeverityLevel } from '@sentry/capacitor';

@Injectable({ providedIn: 'root' })
export class LoggingService {
    private user: { id: string, username: string } = { id: 'unknown', username: 'unknown' };

    constructor(private appSettings: AppSettings) {
        this.appSettings.awaiter().then(() => {
            const id = this.appSettings.get('internalId')
            const studyNumber = this.appSettings.get('study-number');
            this.user.id = id;
            this.user.username = studyNumber;
        });
    }

    write(message: string, logLevel: SeverityLevel = 'log') {
        Sentry.withScope(scope => {
            scope.setUser(this.user)
        })
        return Sentry.captureMessage(message, logLevel);
    }

    writeEvent(message: string, logLevel: SeverityLevel) {
        Sentry.withScope(scope => {
            scope.setUser(this.user)
        })
        return Sentry.captureMessage(message, logLevel);
    }
}
