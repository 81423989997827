import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule} from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';

import {AppComponent} from './app.component';
import { Router, RouteReuseStrategy } from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {DatabaseModule, DatabaseRepository, PouchDbRepository} from '@core/database';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {CloudConnectModule} from '@plugin/cloud-connect';
import {CoreSocketModule} from '@core/socket';
import {SurveyModule} from '@plugin/survey';
import {AppRoutingModule} from './app-routing.module';
import {CalendarModule} from '@plugin/calendar';
import {LifeSectionModule} from '@plugin/life-section';
import {TimeframeModule} from '@library/plugin-timeframe/src/lib/timeframe.module';
import {TimeframeEvaluationModule} from '@plugin/timeframe-evaluation';
import {StatisticsModule} from '@plugin/statistics';
import {EventTemplatesModule} from '@plugin/event-templates';
import {SettingsModule} from '@plugin/settings';
import {DeviceNotificationModule} from '@plugin/device-notifications';
import {NotificationSchedulerService} from './notifications/notification-scheduler.service';
import {CalendarEventInterceptor} from './notifications/listeners/calendar-event.interceptor';
import {GlobalErrorHandler} from './global-error.handler';
import {ColorPickerModule} from './color-picker/color-picker.module';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SnackBarNotifier, ValidationModule} from '@core/validation';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {IonicGestureConfig} from './ionic-gesture.config';
import {DeviceFeedback} from '@ionic-native/device-feedback/ngx';
import {PinLockModule} from './pin-lock/pin-lock.module';
import {HttpClientModule} from '@angular/common/http';
import { Device } from '@ionic-native/device/ngx';
import {DeviceService} from './device.service';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {BackgroundManagerService} from './background-manager.service';
import {WebView} from '@ionic-native/ionic-webview/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileService } from './file.service';
import {environment} from '../environments/environment';
import { TrialService } from './trial.service';
import { PrivacyPolicyModule } from './privacy-policy/privacy-policy.module';
import { AppointmentsService } from './appointments.service';
import { PipesModule } from './localization/pipes/pipes.module';
import { LocalNotifications } from '@capacitor/local-notifications';

registerLocaleData(localeDe, 'de-DE');

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            rippleEffect: false,
            mode: 'md',
            hardwareBackButton: true,
        }),
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        SurveyModule,
        MatInputModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatSnackBarModule,
        CloudConnectModule,
        MatMenuModule,
        TimeframeModule,
        HammerModule,
        ColorPickerModule,
        CloudConnectModule,
        PinLockModule,
        PrivacyPolicyModule,
        HttpClientModule,
        PipesModule,
        ValidationModule.forRoot(SnackBarNotifier),
        DeviceNotificationModule.forRoot(),
        DatabaseModule.forRoot(environment.pouchDbAdapter),
        EventTemplatesModule.forRoot(),
        StatisticsModule.forRoot(),
        CalendarModule.forRoot(),
        LifeSectionModule.forRoot(),
        TimeframeEvaluationModule.forRoot(),
        SettingsModule.forRoot(),
        CoreSocketModule.forRoot({
            hostname: '127.0.0.1',
            port: 2500
        })
    ],
    providers: [
        BarcodeScanner,
        DeviceFeedback,
        Device,
        DeviceService,
        BackgroundManagerService,
        WebView,
        { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: DatabaseRepository, useClass: PouchDbRepository },
        { provide: NotificationSchedulerService, useClass: NotificationSchedulerService },
        { provide: CalendarEventInterceptor, useClass: CalendarEventInterceptor },
        { provide: StatusBar, useClass: StatusBar },
        { provide: LocalNotifications, useValue: LocalNotifications },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        UniqueDeviceID,
        File,
        FileService,
        TrialService,
        AppointmentsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
