import {AppSettings} from '@plugin/settings';
import {WeekTemplateSwitchService} from './week-template-switch.service';
import {WeekTemplateModel} from '@core/models';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';

@Injectable({ providedIn: 'root' })
export class WeekTemplateService {
    private changeStream: BehaviorSubject<WeekTemplateModel[]>;
    private activeStream: BehaviorSubject<WeekTemplateModel>;
    private readonly ready: Promise<void>;

    constructor(private settings: AppSettings, private switchService: WeekTemplateSwitchService) {
        this.ready = this.init();
    }

    get awaiter(): Promise<void> { return this.ready }

    private async init() {
        if (this.ready)
            return;

        await this.settings.awaiter();
        await this.switchService.init();
        this.switchService.cleanup();

        const latestId = this.settings.get<number>('highest-week-template-id', 0);
        if (latestId === 0) {
            const firstTemplate = this.settings.get<WeekTemplateModel>('week-template-0', null);
            if (firstTemplate === null) {
                this.save({
                    id: 0,
                    name: 'Deine Musterwoche'
                })
            }
        }

        this.activeStream = new BehaviorSubject<WeekTemplateModel>((this.getActive()));
        this.settings.changes('active-week-template').subscribe(
            x => this.activeStream.next(
                this.getActive()
            )
        );

        this.changeStream = new BehaviorSubject<WeekTemplateModel[]>((this.getAll()));
        this.settings
            .allChanges<WeekTemplateModel>('week-template')
            .pipe(
                map(x => {
                    return x.map(t => t.value)
                })
            ).subscribe(x => {
                // console.log('next', x);
                this.changeStream.next(x.filter(x => !x['__deleted']))
        })
    }

    save(item: WeekTemplateModel): WeekTemplateModel {
        if (item.id === undefined) {
            item.id = this.settings.get<number>('highest-week-template-id', 0) + 1;
            this.settings.set<number>('highest-week-template-id', item.id);
        }
        this.settings.set<WeekTemplateModel>(`week-template-${item.id}`, item);
        return item;
    }

    delete(item: WeekTemplateModel) {
        item['__deleted'] = true;
        this.settings.set(`week-template-${item.id}`, item)
    }

    getAll(): WeekTemplateModel[] {
        return this.settings
            .getAll<WeekTemplateModel>('week-template')
            .map(x => x.value)
            .filter(x => !x['__deleted'])
    }

    getOne(id: number): WeekTemplateModel {
        return this.getAll().find(x => x.id === id);
    }

    getActive(day: Moment = moment()): WeekTemplateModel {
        return this.switchService.getTemplateForDay(day);
    }

    setActive(item: WeekTemplateModel, day: Moment = moment()) {
        this.switchService
            .addSwitchInstruction(item.id, day)
            .then(() => this.settings.set<number>('active-week-template', item.id))
    }

    $active(): Observable<WeekTemplateModel> { return this.activeStream }
    $weekTemplates(): Observable<WeekTemplateModel[]> { return this.changeStream }
}
