import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatabaseCollectionProvider, DatabaseService } from '@library/core-database/src/public_api';
import { SurveyModel } from '@library/core-models';
import { DATABASE_COLLECTIONS } from '@library/core-models/src/schema';
import { DeviceNotificationService } from '@library/plugin-device-notification/src/public_api';
import { AppSettings } from '@library/plugin-settings/src/public_api';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class TrialService {

    private medianTrialDurationInDays = 49;
    private normalTrialDurationInDays = 14;

    constructor(
        private httpClient: HttpClient,
        private settings: AppSettings,
        private databaseService: DatabaseService,
        private notificationService: DeviceNotificationService,
        private collectionProvider: DatabaseCollectionProvider
    ) {}

    async getTrialToken(): Promise<any> {
        const accessToken = await this.httpClient.get<any>('https://trial.de-rena.de/api/coaches/create-trial-access').toPromise();

        return accessToken;
    }

    isTrial(): boolean {
        return this.settings.has('trial-start');
    }

    async startTrialTimeInterval() {
        await this.settings.awaiter();
        if (!this.hasTrialExpired()) {
            setInterval(() => {
                if (this.hasTrialExpired()) {
                    this.stopTrial();
                }
            }, 180000);
        } else {
            this.stopTrial();
        }
    }

    hasTrialExpired(): boolean {
        const extendedTrial = this.settings.get('trial-extended');
        const startDate = moment(this.settings.get('trial-start'));
        let now = moment();

        const trialExpirationEnd: number = extendedTrial ? this.medianTrialDurationInDays * 24 : this.normalTrialDurationInDays * 24;

        if (now.diff(startDate, 'hours') > trialExpirationEnd) {
            return true;
        } else {
            return false;
        }
    }

    private async stopTrial() {
        await this.removeTrialExpirationNotifications();
        await this.resetSurveyFrequencyInterval();
        this.settings.unset('trial-notifications');
        this.settings.set('trial-expired', true);
        setTimeout(() => {
            this.databaseService.disconnect().then(() => location.reload());
        }, 300);
    }

    async createTrialExpirationNotifications() {
        await this.settings.awaiter();
        const trialStart = this.settings.get('trial-start');
        const extendedTrial = this.settings.get('trial-extended');

        // console.log('createTrialExpirationNotifications', trialStart, extendedTrial, this.settings.get('trial-notifications'));

        const trialDuration = extendedTrial ? this.medianTrialDurationInDays : this.normalTrialDurationInDays;

        if (!trialStart) {
            // console.log('\t no trial start date');
            return;
        }
        const diff = (trialDuration - moment().diff(moment(trialStart), 'days'));
        const ready = await this.notificationService.setup();

        if (ready && (diff <= this.normalTrialDurationInDays)) {
            // console.log('\tcreate notifications');
            let notificationIds = [];
            for (let i = 1; i < diff; i++) {
                let date = new Date();
                date.setHours(12);
                date.setMinutes(0);
                let notificationDate = moment(date).add(i, 'days');
                let id = this.notificationService.schedule(
                    'DE-RENA Testzeitraum',
                    `Dein DE-RENA Testzeitraum läuft in ${diff - i} Tage ab.`,
                    true,
                    notificationDate
                );
                notificationIds.push(id);
            }

            this.settings.set('trial-notifications', notificationIds);
        } else {
            // console.log('\tno notifications created');
        }
    }

    async removeTrialExpirationNotifications() {
        await this.notificationService.setup();
        const ids = this.settings.get('trial-notifications');
        if (!ids || !ids.length) {
            return;
        }

        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            await this.notificationService.remove(id);
        }
    }

    async resetSurveyFrequencyInterval() {
        const surveyCollection = this.collectionProvider.use<SurveyModel>(DATABASE_COLLECTIONS.SurveyCollection);

        const doc = await surveyCollection.findOne().exec();

        if (!doc) {
            return;
        }

        const survey = doc.toJSON() as SurveyModel;

        survey.interval = 2;
        survey.frequency = 'WEEKLY';
        survey.until = moment().add(6, 'months').toISOString();

        await surveyCollection.atomicUpsert(survey);
    }

    async dequeueTrialNotifications() {
        // console.log('dequeue trial notifications...');
        await this.notificationService.setup();
        const notifications = await this.notificationService.getPending();
        for (let notification of notifications) {
            if (notification.title.includes('Testzeitraum')) {
                await this.notificationService.remove(notification.id);
            }
        }
    }
}
